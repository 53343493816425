<template>
  <div class="sells q-px-md">

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="far fa-address-card" label="Cadastrar Cliente" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVOS CLIENTES -->
    <q-dialog v-model="modalClient" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO CADASTRO DE CLIENTES
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVO CLIENTE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="client" type="text" label="Nome do Cliente" />
            <q-input class="twoFields" outlined v-model="cpf" type="text" label="CPF" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="mail" type="text" label="E-Mail" />
            <q-input class="twoFields" outlined v-model="phone" type="text" label="Celular" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="insta" type="text" label="Instagram" />
            <q-input class="threeFields" outlined v-model="product" type="text" label="Preferência" />
            <q-input class="threeFields" outlined v-model="birthday" type="date" stack-label label="Aniversário" />
            <!-- <q-input class="threeFields" outlined v-model="birthday" label="Aniversário" >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="birthday" :locale="ptBR" mask="DD/MM/YYYY" today-btn >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Confirmar" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input> -->
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="address" type="text" label="Endereço" />
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDelete" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Dialog } from 'quasar';
import locale from 'quasar/lang/pt-BR';
import DateFormat from '../../helpers/DateFormat';
// import dateToString from '../../../helpers/dateToString.js';
import { defaultColor } from '../../helpers/defaultColor';
import DateEnglish from '../../helpers/DateEnglish';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalClient: false,
      filter: null,
      ptBR: locale.date,
      cols: [
        { name: 'client',     align: 'center', label: 'Nome do Cliente',      field: 'client',    sortable: true },
        { name: 'cpf',        align: 'center', label: 'CPF',                  field: 'cpf',       sortable: true },
        { name: 'phone',      align: 'center', label: 'Celular',              field: 'phone',     sortable: true },
        { name: 'mail',       align: 'center', label: 'E-Mail',               field: 'mail',      sortable: true },
        { name: 'insta',      align: 'center', label: 'Instagram',            field: 'insta',     sortable: true },
        { name: 'product',       align: 'center', label: 'Preferência',      field: 'product',      sortable: true },
        { name: 'birthday',   align: 'center', label: 'Aniversário',          field: 'birthday',  sortable: true,
          format: val => DateFormat(val)
        },
        { name: 'qtd',        align: 'center', label: 'Qtdade Comprada',      field: 'qtd',       sortable: true,
          format: val => parseInt(val)
        },
        { name: 'value',      align: 'center', label: 'Valor Total Comprado', field: 'value',     sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'address',    align: 'center', label: 'Endereço',             field: 'address',      sortable: true },
      ],
      rows: [],

      //~> PARA O MODAL
      client: null,
      cpf: null,
      phone: null,
      mail: null,
      insta: null,
      product: null,
      birthday: null,
      qtd: null,
      value: null,
      address: null,
      recno: null,
      rebuild: true,

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() { //~> OK
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.client = ''
      this.cpf = ''
      this.phone = ''
      this.mail = ''
      this.insta = ''
      this.product = ''
      this.birthday = ''
      this.qtd = ''
      this.value = ''
      this.address = ''
    },
    validFields() { //~> NÃO IREMOS A PRINCIPIO VALIDAR OS CAMPOS
      // if(
      //   this.client == '' || this.client == null ||
      //   this.cpf == '' || this.cpf == null ||
      //   this.phone == '' || this.phone == null ||
      //   this.address == '' || this.address == null
      // ) {
      //   this.statusFields = false;
      //   Dialog.create({
      //     title: 'Gestão Eletrônicos',
      //     message: 'Favor preencher campos obrigatórios (Cliente, CPF, Celular e Endereço)'
      //   });
      // } else {
      //   this.statusFields = true;
      // }
    },
    onAdd() { //~> OK
      this.resetForm();
      this.modalClient = true;
    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalClient = false;
      })
    },
    async onSave() { //~> OK

      let client = this.client == '' || this.client == null ? '-' : this.client
      let cpfCli= this.cpf == '' || this.cpf == null ? '-' : this.cpf
      let phone = this.phone == '' || this.phone == null ? '-' : this.phone
      let address = this.address == '' || this.address == null ? '-' : this.address
      let mail = this.mail == '' || this.mail == null ? '-' : this.mail
      let insta = this.insta == '' || this.insta == null ? '-' : this.insta
      let product = this.product == '' || this.product == null ? '-' : this.product
      let enterprise = this.enterprise == '' || this.enterprise == null ? '-' : this.enterprise
      
      let bday = this.birthday == '' || this.birthday == null ? 19000101 : String(this.birthday).replaceAll('-', '')  //dateToString(this.birthday)
      try {
        if(this.editRow) { //~> OK

          await this.updateData({
            table: 'GE_CLIENTS',
            fields: {
              'GE_NAME': this.client,
              'GE_CPF': this.cpf,
              'GE_PHONE': this.phone,
              'GE_ADDRESS': this.address,
              'GE_MAIL': this.mail,
              'GE_INSTAGRAM': this.insta,
              'GE_TEAMHEART': this.product,
              'GE_BIRTHDAY': bday,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' ',
            },
            match: {
              'RECNO': this.recno
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });

          this.resetForm();
        } else { //~> OK
          // let cpf = await this.rpc({
          //   function: 'verify_clients',
          //   enterprise: localStorage.getItem('enterprise'),
          //   category: this.cpf
          // })

          // if (cpf.length > 0) { //~> OK

          //   if (cpf[0].deleted === '*') { //~> OK
          //     Dialog.create({
          //       title: 'Gestão Eletrônicos',
          //       message: 'Este cliente foi excluido de sua base, deseja reativá-lo?',
          //       cancel: true
          //     }).onOk(async () => {
          //       await this.updateData({
          //         table: 'GE_CLIENTS',
          //         fields: {
          //           'DELETED': ' ',
          //         },
          //         match: {
          //           'RECNO': cpf[0].recno
          //         }
          //       })

          //       this.executeQuery();
          //       this.filter = cpf[0].cpf
          //     })
          //   } else { //~> OK
          //     Dialog.create({
          //       title: 'Gestão Eletrônicos',
          //       message: 'Este cliente já está cadastrado na base!'
          //     })

          //     this.filter = this.cpf
          //   }
          // } else {
            await this.insertData({
              table: 'GE_CLIENTS',
              fields: {
                'GE_NAME': client,
                'GE_CPF': cpfCli,
                'GE_PHONE': phone,
                'GE_ADDRESS': address,
                'GE_MAIL': mail,
                'GE_INSTAGRAM': insta,
                'GE_TEAMHEART': product,
                'GE_BIRTHDAY': bday,
                'GE_ENTERPRISE': enterprise
              }
            })

            Dialog.create({
              title: 'Gestão Eletrônicos',
              message: 'Cliente adicionado com sucesso!'
            });

            this.resetForm();
          // }
        }

        this.resetForm();
        this.modalClient = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'
        await this.updateData({
          table: 'GE_CLIENTS',
          fields: {
            'DELETED': '*',
          },
          match: {
            'RECNO': this.recno
          }
        })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.modalClient = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let clients = await this.rpc({
        function: 'base_clients',
        enterprise: localStorage.getItem('enterprise')
      })

      this.rows = clients.map((cli) => {
        return {
          recno: cli.recno,
          client: cli.client,
          cpf: cli.cpf,
          phone: cli.phone,
          mail: cli.mail,
          insta: cli.insta,
          product: cli.productheart,
          birthday: cli.birthday,
          qtd: cli.qtd,
          value: cli.value,
          address: cli.address
        }
      });

    },
    onEditRow(_, row) { //~> OK

      this.client = row.client
      this.cpf = row.cpf
      this.phone = row.phone
      this.mail = row.mail
      this.insta = row.insta
      this.product = row.product
      this.birthday = DateEnglish(row.birthday)   //DateFormat(row.birthday)
      this.address = row.address
      this.recno = row.recno

      this.editRow = true;
      this.modalClient = true;
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Clientes.csv");
      link.click();
    },
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
</style>